<template>
  <div>
    <b-form-group
      label="Nombre singular"
      label-for="input-visible_name"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-input
        id="input-visible_name"
        name="input-visible_name"
        v-model="$v.alternative_name.visible_name.$model"
        :state="validateState('visible_name')"
        aria-describedby="input-visible_name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-visible_name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Nombre plural"
      label-for="input-visible_name_plural"
      label-cols="4"
      label-cols-sm="4"
      class="label"
    >
      <b-form-input
        id="input-visible_name_plural"
        name="input-visible_name_plural"
        v-model="$v.alternative_name.visible_name_plural.$model"
        :state="validateState('visible_name_plural')"
        aria-describedby="input-visible_name_plural-live-feedback"
        size="sm"
      ></b-form-input>
      <!-- <b-form-textarea
        id="input-visible_name_plural"
        name="input-visible_name_plural"
        v-model="$v.alternative_name.visible_name_plural.$model"
        :state="validateState('visible_name_plural')"
        aria-describedby="input-visible_name_plural-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea> -->
      <b-form-invalid-feedback id="input-visible_name_plural-live-feedback"
        >Este campo debe tener al menos 4 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="alternative_name"></ModificationDate>
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.alternative_name.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteAlternativeName"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "AlternativeNameForm",
  mixins: [validationMixin],
   components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    AlternativeName: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          visible_name: "",
          visible_name_plural: "",
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alternative_name: this.AlternativeName
        ? {
            id: this.AlternativeName.id,
            visible_name: this.AlternativeName.visible_name,
            visible_name_plural: this.AlternativeName.visible_name_plural,
            verbose_name: this.AlternativeName.verbose_name,
            verbose_name_plural: this.AlternativeName.verbose_name_plural,
            table_name: this.AlternativeName.table_name,
            updated_by: this.AlternativeName.updated_by,
            update_date: this.AlternativeName.update_date,
          }
        : {
            id: generateUniqueId(),
            visible_name: "",
            visible_name_plural: "",
            verbose_name: "",
            verbose_name_plural: "",
            table_name: "",
          },
    };
  },
  validations: {
    alternative_name: {
      visible_name: {
        required,
        minLength: minLength(1),
      },
      visible_name_plural: {
        required,
        minLength: minLength(1),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.alternative_name[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.alternative_name.$touch();
      if (this.$v.alternative_name.$anyError) {
        return;
      }
      if (isNaN(this.alternative_name.id)) {
        this.createAlternativeName();
      } else this.updateAlternativeName();
    },
    createAlternativeName() {
      // this.$store
      //   .dispatch(
      //     names.POST_ALTERNATIVE_NAME,
      //     this.alternative_name,
      //   )
      //   .then((response) => {
      //     toast("Tipo de Asignatura creada.");
      //     this.$emit("created", response);
      //   });
    },
    updateAlternativeName() {
      this.$store
        .dispatch(names.UPDATE_ALTERNATIVE_NAME, this.alternative_name)
        .then((response) => {
          toast(String("Actualizado"));
          this.$emit("updated", response);
        });
    },
    deleteAlternativeName() {
      this.$swal({
        title: `"¿Está seguro de que lo desea eliminar?"`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_ALTERNATIVE_NAME,
            this.alternative_name.id
          );
          this.$emit("deleted", this.alternative_name);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>